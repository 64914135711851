<template>
  <thead v-if="filterRowShow">
    <tr role="row">
      <th tabindex="0" rowspan="1" colspan="1">#</th>
      <th
        tabindex="0"
        rowspan="1"
        colspan="1"
        v-for="(column, i) in columns"
        :key="i"
      >
        <div style="position: relative">
          <input
            v-if="column.type === 'text'"
            type="text"
            class="form-control"
            v-model="column.search"
            @keyup="$emit('search', column)"
            autocomplete="off"
          />
          <input
            v-if="column.type === 'number'"
            type="number"
            class="form-control"
            v-model="column.search"
            @keyup="$emit('search', column)"
            autocomplete="off"
          />
          <date-picker
            class="NeoDataPicker"
            v-model="column.search"
            :config="optionsDate"
            :name="column.key"
            v-else-if="column.type === 'date'"
            @dp-change="$emit('search', column)"
            autocomplete="off"
          />
          <div v-else-if="column.type === 'Beetwen'">
            <date-picker
              class="NeoDataPicker"
              v-model="column.from"
              :config="optionsDate"
              :name="column.key"
              @dp-change="$emit('search', column)"
              autocomplete="off"
              aria-placeholder="od"
            />
            <date-picker
              class="NeoDataPicker"
              v-model="column.to"
              :config="optionsDate"
              :name="column.key"
              @dp-change="$emit('search', column)"
              autocomplete="off"
              aria-placeholder="do"
            />
          </div>
          <select
            class="form-control"
            :name="column.key"
            v-validate="'required'"
            v-model="column.search"
            v-else-if="column.type === 'list'"
            @change="$emit('search', column)"
            autocomplete="off"
          >
            <option
              v-for="(item, i) in column.list"
              :key="i"
              :value="item.name"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "FilterComponent",
  props: {
    columns: {
      type: Array,
      required: true
    },
    filterRowShow: {
      type: Boolean,
      default: true
    }
  }
};
</script>
