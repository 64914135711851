<template>
  <tfoot>
    <tr role="row">
      <th tabindex="0" rowspan="1" colspan="1">Lp</th>
      <th
        tabindex="0"
        rowspan="1"
        colspan="1"
        aria-sort="ascending"
        v-for="(column, i) in columns_list"
        :key="i"
      >
        {{ column.name }}
      </th>
    </tr>
  </tfoot>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    columns_list: {
      type: Array,
      required: true
    }
  }
};
</script>
