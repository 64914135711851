<template>
  <tbody>
    <tr role="row" v-for="(row, i) in table_list" :key="i">
      <td>
        {{ entries * (current_page - 1) + i + 1 }}
      </td>
      <td
        v-for="(column, j) in columns_list"
        :key="i + '-' + j"
        :class="columnClass(column)"
      >
        <span v-if="column.filter === ''">
          {{
            (column.type === "number" || column.type === "text") &&
            row[column.alias] !== "" &&
            row[column.alias] !== null &&
            row[column.alias] !== undefined 
              ? row[column.alias]
              : row[column.key]
          }}
        </span>
        <span
          v-if="column.filter === 'btn'"
          v-html="prepareDataBtn(row, column)"
          @click="prepareFunctionToRun(row, column)"
        >
        </span>
        <span v-if="column.filter === 'boolean'">
          {{
            (row[column.alias] ? row[column.alias] : row[column.key])
              | toBoolean
          }}
        </span>
        <span v-if="column.filter === 'price'">
          {{
            (row[column.alias] ? row[column.alias] : row[column.key])
              | toCurrency
          }}
        </span>
        <span v-if="column.filter === 'details'">
          <router-link :to="`${categoryName}/${row.id}`">
            <i class="fa fa-edit"></i>
          </router-link>
        </span>
        <span v-if="column.filter === 'delete'">
          <div class="btn -btn-primary" @click="destroy(row.id)">
            <i class="fa fa-trash"></i>
          </div>
        </span>
        <span v-if="column.filter === 'address'">
          {{
            (row[column.alias] ? row[column.alias] : row[column.key])
              | toAddress
          }}
        </span>
        <span v-if="column.filter === 'paymentDelivery'">
          {{
            (row[column.alias] ? row[column.alias] : row[column.key])
              | toPaymentDelivery
          }}
        </span>
        <span v-if="column.filter === 'discountCode'">
          {{
            (row[column.alias] ? row[column.alias] : row[column.key])
              | toDiscountCode
          }}
        </span>
        <span v-if="column.filter === 'link'">
          <a
            :href="row[column.alias] ? row[column.alias] : row[column.key]"
            target="_blank"
          >
            {{ row[column.alias] ? row[column.alias] : row[column.key] }}
          </a>
        </span>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { php } from "locutus";

export default {
  name: "RowComponent",
  props: {
    categoryName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    table_list: {
      type: Array,
      required: true,
    },
    current_page: {
      type: Number,
      required: true,
    },
    entries: {
      type: Number,
      required: true,
    },
    columns_list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    prepareDataBtn(row, column) {
      return column.btn(row[column.key], row, column.key);
    },
    columnClass(column) {
      let classText = column.class !== undefined ? column.class : "";
      if (column.filter !== undefined) {
        if (column.filter == "boolean") {
          classText += "text-center";
        }
        if (column.filter == "number") {
          classText += "text-right";
        }
      }
      return classText;
    },
    prepareFunctionToRun(row, column) {
      if (column.touch) {
        column.function(row[column.key], row, column.key);
        this.$forceUpdate();
        if (column.dont_refresh === undefined || !column.dont_refresh) {
          this.getData(`?page= ${this.current_page}`);
        }
      }
    },
    destroy(id) {
      if (confirm("Na pewno chcesz usunąć?") == true) {
        this.$http.delete(`${this.url.replace(/\/$/, "")}/${id}`).then(() => {
          this.$emit("loading", true);
          this.$emit("getData", 1);
        });
      }
    },
  },
  filters: {
    toCurrency: function(value) {
      // if (typeof value !== "number") {
      //   return value;
      // }
      let formatter = new Intl.NumberFormat("pl", {
        style: "currency",
        currency: "PLN",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    toDetails: function(value) {
      console.log(value);
    },
    toBoolean: function(value) {
      if (value == "on" || value == "1") {
        return "✔";
      } else {
        return "✘";
      }
    },
    toAddress: (value) => {
      if (value) {
        const unserializedData = php.var.unserialize(value);
        return `<p>
                  <strong>
                  ${unserializedData.name}
                  ${unserializedData.surname}
                  </strong></br>
                  <strong>${unserializedData.company}</strong></br>
                  <strong>${unserializedData.email}</strong></br>
                  Faktura: <strong>${
                    unserializedData.invoice === "on" ? "tak" : "nie"
                  }</strong></br>
              </p>`;
      }
    },
    toPaymentDelivery: (value) => {
      if (value) {
        const unserializedData = php.var.unserialize(value);
        return `<p>
                  Sposób dostawy: <strong>${unserializedData.delivery_name_pl}</strong></br></br>
                  Sposób płatności: <strong>${unserializedData.payment_name}</strong></br>
              </p>`;
      }
    },
    toDiscountCode: (value) => {
      if (value) {
        const unserializedData = php.var.unserialize(value);
        if (unserializedData) {
          return `<p>
                    Kod: <strong>${unserializedData.code}</strong></br>
                    Typ: <strong>${unserializedData.code_type}</strong></br>
                    Wartość: <strong>${unserializedData.discount}${unserializedData.discount_symbol}</strong></br>
                    ${unserializedData.message}</br>
                </p>`;
        }
        return value;
      }
    },
  },
};
</script>
