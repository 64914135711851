<template>
  <div class="ibox ">
    <div class="ibox-title">
      <h5>{{ title }}</h5>
      <div class="ibox-tools">
        <div
          v-if="collapseId"
          class="collapse-arrow"
          :class="visibleMain ? null : 'collapsed'"
          :v-b-toggle="[`${collapseId}`, `${collapseId}` + '-description']"
          v-b-tooltip.hover
          :title="visibleMain ? 'zwiń' : 'rozwiń'"
          placement="top"
          :aria-expanded="visibleMain ? 'true' : 'false'"
          :aria-controls="`${collapseId}`"
          @click="collapseContent('main')"
        >
          <b-icon class="ibox-icon" icon="arrows-collapse"></b-icon>
        </div>
        <div
          :aria-expanded="visibleDesc ? 'true' : 'false'"
          :aria-controls="`${collapseId}` + '-description'"
          :v-b-toggle="[`${collapseId}` + '-description']"
          id="description"
          @click="collapseContent('desc')"
        >
          <b-icon class="ibox-icon" icon="question"></b-icon>
        </div>
        <b-tooltip triggers="hover" placement="top" target="description">
          {{ description }}
        </b-tooltip>
        <slot name="tools"> </slot>
      </div>
    </div>
    <b-collapse
      class="ibox-content"
      v-model="visibleDesc"
      :id="`${collapseId}` + '-description'"
    >
      {{ description }}
    </b-collapse>
    <b-collapse
      v-model="visibleMain"
      :id="`${collapseId}`"
      class="ibox-content"
      :class="{ 'sk-loading': loading }"
    >
      <div class="sk-spinner sk-spinner-wave">
        <div class="sk-rect1"></div>
        <div class="sk-rect2"></div>
        <div class="sk-rect3"></div>
        <div class="sk-rect4"></div>
        <div class="sk-rect5"></div>
      </div>

      <slot name="content" :setLoading="setLoading" @eventEmite="eventHandler">
      </slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "Ibox",
  data() {
    return {
      loading: true,
      visibleMain: true,
      visibleDesc: true
    };
  },
  props: {
    title: {
      type: String,
      default: "Lista"
    },
    eventHandler: {
      type: Function
    },
    collapseId: {
      type: [String, Number],
      default: "collapse-1"
    },
    description: {
      type: String,
      default: "Tutaj wstaw opis"
    }
  },
  watch: {
    visibleMain(newVal) {
      localStorage.setItem(`${this.$parent._name}-${this.collapseId}`, newVal);
    },
    visibleDesc(newVal) {
      localStorage.setItem(
        `${this.$parent._name}-${this.collapseId}-description`,
        newVal
      );
    }
  },
  created() {
    this.manageCollapse();
  },
  methods: {
    setLoading(e) {
      this.loading = e;
    },
    parse(el, type) {
      let value = typeof el !== Boolean ? JSON.parse(el) : el;
      switch (type) {
        case "visibleMain":
          this.visibleMain = value;
          break;
        case "visibleDesc":
          this.visibleDesc = value;
      }
    },
    manageCollapse() {
      localStorage.getItem(`${this.$parent._name}-${this.collapseId}`)
        ? this.parse(
            localStorage.getItem(`${this.$parent._name}-${this.collapseId}`),
            "visibleMain"
          )
        : localStorage.setItem(
            `${this.$parent._name}-${this.collapseId}`,
            this.visibleMain
          );

      localStorage.getItem(
        `${this.$parent._name}-${this.collapseId}-description`
      )
        ? this.parse(
            localStorage.getItem(
              `${this.$parent._name}-${this.collapseId}-description`
            ),
            "visibleDesc"
          )
        : localStorage.setItem(
            `${this.$parent._name}-${this.collapseId}-description`,
            this.visibleDesc
          );
    },
    collapseContent(value) {
      switch (value) {
        case "main":
          this.visibleMain = !this.visibleMain;
          this.visibleMain === true
            ? (this.visibleDesc = true)
            : (this.visibleDesc = false);
          break;
        case "desc":
          this.visibleDesc = !this.visibleDesc;
      }
    }
  }
};
</script>
