<template>
  <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4">
    <BaseComponent
      :entries="entries"
      :current_page="current_page"
      :last_page="last_page"
      :total="total"
      :to="to"
      :excel="excel"
      :paginationShow="paginationShow"
      @getData="getData"
      @downloadExcel="downloadExcel"
      @entries_change="entriesChange"
    />
    <div id="scroll1" class="table-responsive">
      <div style="height: 1px"></div>
    </div>
    <div id="scroll2" class="table-responsive">
      <table
        class="
          table table-striped table-bordered table-hover
          dataTables-example
          dataTable
        "
        id="DataTables_Table_0"
        aria-describedby="DataTables_Table_0_info"
        role="grid"
      >
        <FilterComponent
          @search="search"
          :columns="columns"
          :filterRowShow="filterRowShow"
        />
        <HeaderComponent
          :columns_list="columns_list"
          @change-sorting="changeSorting"
        />
        <RowComponent
          :columns_list="columns_list"
          :entries="entries"
          :current_page="current_page"
          :table_list="table_list"
          :categoryName="categoryName"
          :url="url"
          @getData="getData"
        />
        <FooterComponent :columns_list="columns_list" />
      </table>
    </div>
    <PaginationComponent
      v-if="paginationShow == true"
      :current_page="current_page"
      :last_page="last_page"
      :entries="entries"
      :total="total"
      :to="to"
      @loading="n => $emit('loading', n)"
      @getData="getData"
    />
  </div>
</template>

<script>
import BaseComponent from "./components/BaseComponent";
import FilterComponent from "./components/FilterComponent";
import HeaderComponent from "./components/HeaderComponent";
import RowComponent from "./components/RowComponent";
import PaginationComponent from "./components/PaginationComponent";
import FooterComponent from "./components/FooterComponent";
import qs from "qs";
export default {
  name: "DataTable",
  data() {
    return {
      entries: 10,
      pagination_link_count: 7,
      current_page: 1,
      last_page: 1,
      total: 50,
      to: 0,
      table_list: [],
      columns_list: [],
      timer: undefined
    };
  },
  components: {
    BaseComponent,
    PaginationComponent,
    HeaderComponent,
    FilterComponent,
    RowComponent,
    FooterComponent
  },
  props: {
    url: {
      type: String,
      required: true
    },
    excel: {
      type: Boolean,
      default: false
    },
    manualColumns: {
      type: Array
    },
    filterRowShow: {
      type: Boolean,
      default: true
    },
    paginationShow: {
      type: Boolean,
      default: true
    },
    detailsPath: {
      type: String,
      required: false
    }
  },
  computed: {
    columns() {
      let helperArray = [];
      this.columns_list.forEach(element => {
        if (element.show === true || element.show === undefined) {
          helperArray.push(element);
        } else {
          element.type = "";
          helperArray.push(element);
        }
      });
      return helperArray;
      // return this.columns_list.filter(
      //   column => column.show === undefined || column.show === true
      // );
    },
    categoryName() {
      return this.detailsPath !== undefined
        ? this.detailsPath
        : this.url
            .split("?")[0]
            .split("/")
            .at(-1);
    }
  },
  watch: {
    entries: async function() {
      let query = {};
      if (window.location.search != "") {
        let queryArr = window.location.search.split("?")[1].split("&");
        queryArr.forEach(el => {
          let param = el.split("=");
          query[param[0]] = param[1];
        });
      }
      // let query = Object.assign({}, this.$route.query);
      query.entries = this.entries;
      let url = `${this.$route.path}?${qs.stringify(query, {
        offset: 0,
        encode: false
      })}`;
      await history.pushState({}, null, url);
    },
    current_page: async function() {
      let query = {};
      if (window.location.search != "") {
        let queryArr = window.location.search.split("?")[1].split("&");
        queryArr.forEach(el => {
          let param = el.split("=");
          query[param[0]] = param[1];
        });
      }
      query.page = this.current_page;
      let url = `${this.$route.path}?${qs.stringify(query, {
        offset: 0,
        encode: false
      })}`;
      await history.pushState({}, null, url);
    },
    url: function() {
      this.getData();
    }
  },
  created() {
    this.entries = Number(this.$route.query.entries) || Number(this.entries);
    this.current_page =
      Number(this.$route.query.page) || Number(this.current_page);
    if (this.manualColumns) {
      this.columns_list = this.manualColumns;
    }
    this.getData();
  },
  mounted() {
    document.querySelector("#scroll1").addEventListener("scroll", this.scroll);
    document.querySelector("#scroll2").addEventListener("scroll", this.scroll);
  },
  updated() {
    document.querySelector("#scroll1 div").style.width = `${
      document.querySelector("#scroll2 table").clientWidth
    }px`;
  },
  beforeDestroy() {
    if (document.querySelector("#scroll1")) {
      document
        .querySelector("#scroll1")
        .removeEventListener("scroll", this.scroll);
    }
    if (document.querySelector("#scroll2")) {
      document
        .querySelector("#scroll2")
        .removeEventListener("scroll", this.scroll);
    }
  },
  methods: {
    downloadExcel() {
      console.log("DO ZROBIENIA ENDPOINTY");
      // this.$emit("loading", true);
      // const filter = {
      //   dateStart: null,
      //   dateEnd: null,
      //   addData: null,
      //   excel: true,
      //   sort: {}
      // };
      // this.columns.map(column => {
      //   filter[column.key] = column.search || "";
      //   if (column.sort !== undefined && column.sort !== null) {
      //     filter.sort[column.key] = column.sort;
      //   }
      //   return filter;
      // });
      // this.$forceUpdate();
      // this.$nextTick(() => {
      //   this.$http({
      //     url: `${this.url}/download`,
      //     method: "POST",
      //     responseType: "blob",
      //     data: this.columns
      //   })
      //     .then(response => {
      //       const url = window.URL.createObjectURL(new Blob([response.data]));
      //       const link = document.createElement("a");
      //       link.href = url;
      //       link.setAttribute(
      //         "download",
      //         `${this.fileName}-${this.$moment().format("YYYY-mm-d")}.xlsx`
      //       );
      //       document.body.appendChild(link);
      //       link.click();
      //     })
      //     .catch(err => {
      //       this.$toastr.error(err);
      //       this.$emit("loading", false);
      //     })
      //     .finally(() => {
      //       this.$toastr.success("Pobrany excel");
      //       this.$emit("loading", false);
      //     });
      // });
    },
    async search(column = true) {
      let query = {};
      if (window.location.search != "") {
        let queryArr = window.location.search.split("?")[1].split("&");
        queryArr.forEach(el => {
          let param = el.split("=");
          if (param[1].length > 0) {
            query[param[0]] = param[1];
          }
        });
      }
      let searchKey = "search-" + (column.alias ? column.alias : column.key);
      query[searchKey] = column.search;

      if (column.search === "") {
        delete query["search-" + (column.alias ? column.alias : column.key)];
      }

      let url = `${this.$route.path}?${qs.stringify(query, {
        offset: 0,
        encode: false
      })}`;
      await history.pushState({}, null, url);

      this.$forceUpdate();
      this.$nextTick(() => {
        if (!column.search) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.getData();
          }, 1500);
          return 0;
        }
        if (column?.search?.length >= 1) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.$route.query.page = 1;
            this.getData();
          }, 1500);
        } else if (column?.from?.length > 1 && column?.to?.length > 1) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.$route.query.page = 1;
            this.getData();
          }, 1500);
        }
      });
    },
    entriesChange(value) {
      this.entries = Number(value);
      this.getData();
    },
    getData(page = 1, refresh = false) {
      this.$emit("loading", true);

      if (refresh) {
        let url = this.$route.path.concat("?page=1&entries=10");
        this.entries = 10;
        history.pushState({}, null, url);
        this.columns_list.forEach(column => {
          column.search = "";
        });
      }

      const urlQueryPage = this.$route.query.page || 1;
      const mainPage = page == 1 ? urlQueryPage : page;
      const filter = {
        entries: this.entries,
        page: mainPage
      };
      this.columns.map(column => {
        if (column.alias) {
          filter[column.alias] = column;
        } else {
          filter[column.key] = column;
        }
        return filter;
      });
      const urlParams = new URLSearchParams(window.location.search);
      let paramsObj = {};
      for (const [key, value] of urlParams) {
        if (key.includes("search")) {
          let str = key.split("-")[1];
          paramsObj[str] = value;
        }
      }
      Object.entries(paramsObj).forEach(el => {
        if (Object.keys(filter).includes(el[0])) {
          filter[el[0]].search = el[1];
        } else {
          filter[el[0]] = {
            search: `${el[1]}`
          };
        }
      });

      this.$forceUpdate();
      this.$nextTick(() => {
        let params = qs.stringify(filter, { offset: 0, encode: false });
        let url = this.url.includes("?")
          ? `${this.url}&${params}`
          : `${this.url}?${params}`;
        this.$http
          .get(url)
          .then(resp => {
            if (
              this.manualColumns == undefined &&
              resp.data.columns &&
              this.columns_list.length == 0
            ) {
              this.columns_list = resp.data.columns;
            }

            this.table_list = resp.data.data;
            this.entries =
              resp.data.per_page !== undefined ? resp.data.per_page : 1;
            this.current_page =
              resp.data.current_page !== undefined ? resp.data.current_page : 1;
            this.last_page =
              resp.data.last_page !== undefined ? resp.data.last_page : 1;
            this.total = resp.data.total !== undefined ? resp.data.total : 1;
            this.to = resp.data.to !== undefined ? resp.data.to : 1;

            if (Object.keys(paramsObj).length > 0) {
              Object.entries(paramsObj).forEach(el => {
                for (let i = 0; i < this.columns_list.length; i++) {
                  if (this.columns_list[i].alias == el[0]) {
                    this.columns_list[i].search = el[1];
                  } else if (
                    this.columns_list[i].key == el[0] &&
                    !this.columns_list[i].alias
                  ) {
                    this.columns_list[i].search = el[1];
                  }
                }
              });
            }

            this.$emit("loading", false);
            this.$emit("downloadedData", this.table_list);
          })
          .catch(() => {
            this.$emit("loading", false);
          });
      });
    },
    scroll(e) {
      if (e.target.id === "scroll1") {
        document.querySelector("#scroll2").scrollLeft = e.target.scrollLeft;
      } else {
        document.querySelector("#scroll1").scrollLeft = e.target.scrollLeft;
      }
    },
    changeSorting(column) {
      if (column.sortable) {
        if (column.sort === null || column.sort === undefined) {
          column.sort = "ASC";
        } else if (column.sort === "ASC") {
          column.sort = "DESC";
        } else if (column.sort === "DESC") {
          column.sort = null;
        }
        this.$forceUpdate();
        this.getData();
      }
    }
  }
};
</script>
