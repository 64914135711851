<template>
  <thead>
    <tr role="row">
      <th tabindex="0" rowspan="1" colspan="1">Lp</th>
      <th
        :class="{
          sorting:
            column.sortable &&
            (column.sort == null || column.sort == undefined),
          sorting_asc: column.sortable && column.sort == 'ASC',
          sorting_desc: column.sortable && column.sort == 'DESC'
        }"
        @click="$emit('change-sorting', column)"
        tabindex="0"
        rowspan="1"
        colspan="1"
        aria-sort="ascending"
        v-for="(column, i) in columns_list"
        :key="i"
      >
        {{ column.name }}
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    columns_list: {
      type: Array,
      required: true
    }
  }
};
</script>
