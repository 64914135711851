<template>
  <div
    class="dataTables_paginate paging_simple_numbers"
    id="DataTables_Table_0_paginate"
  >
    <ul class="pagination">
      <li
        class="paginate_button page-item previous"
        :class="{ disabled: current_page === 1 }"
      >
        <a
          href="#"
          aria-controls="DataTables_Table_0"
          data-dt-idx="0"
          tabindex="0"
          class="page-link"
          @click.prevent="$emit('getData', current_page - 1)"
        >
          Previous
        </a>
      </li>
      <li
        class="paginate_button page-item"
        :class="{ active: current_page === 1 }"
        v-if="start >= 1"
      >
        <a
          href="#1"
          aria-controls="DataTables_Table_0"
          :data-dt-idx="1"
          tabindex="0"
          class="page-link"
          @click.prevent="$emit('getData', current_page === 1)"
        >
          1
        </a>
      </li>
      <li class="paginate_button page-item" v-if="start > 1">
        <a
          href="#"
          aria-controls="DataTables_Table_0"
          :data-dt-idx="1"
          tabindex="0"
          class="page-link"
        >
          ...
        </a>
      </li>
      <li
        class="paginate_button page-item"
        :class="{ active: current_page === start + page_number }"
        v-for="(page_number, i) in end - start"
        :key="i"
      >
        <a
          :href="'#' + (start + page_number)"
          aria-controls="DataTables_Table_0"
          :data-dt-idx="start + page_number"
          tabindex="0"
          class="page-link"
          @click.prevent="$emit('getData', start + page_number)"
        >
          {{ start + page_number }}
        </a>
      </li>
      <li class="paginate_button page-item" v-if="end < last_page">
        <a
          href="#"
          aria-controls="DataTables_Table_0"
          :data-dt-idx="1"
          tabindex="0"
          class="page-link"
        >
          ...
        </a>
      </li>
      <li
        class="paginate_button page-item"
        :class="{ active: current_page === last_page }"
        v-if="end < last_page"
      >
        <a
          :href="'#' + last_page"
          aria-controls="DataTables_Table_0"
          :data-dt-idx="last_page"
          tabindex="0"
          class="page-link"
          @click.prevent="$emit('getData', last_page)"
        >
          {{ last_page }}
        </a>
      </li>
      <li
        class="paginate_button page-item next"
        :class="{ disabled: current_page === last_page }"
      >
        <a
          href="#"
          aria-controls="DataTables_Table_0"
          data-dt-idx="4"
          tabindex="0"
          class="page-link"
          @click.prevent="$emit('getData', current_page + 1)"
        >
          Next
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  data() {
    return {
      pagination_link_count: 7
    };
  },
  computed: {
    numberPages() {
      return Math.ceil(this.total / this.entries);
    },
    start() {
      return this.current_page - this.pagination_link_count > 0
        ? this.current_page - this.pagination_link_count
        : 1;
    },
    end() {
      return this.current_page + this.pagination_link_count < this.last_page
        ? this.current_page + this.pagination_link_count
        : this.last_page;
    }
  },
  props: {
    entries: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    to: {
      type: Number,
      required: true
    },
    current_page: {
      type: Number,
      required: true
    },
    last_page: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleNext() {
      if (this.currentPage < this.numberPages) {
        this.currentPage = this.currentPage + 1;
      }
    },
    handlePrev() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
      }
    },
    handlePageChoice(index) {
      this.currentPage = index + 1;
    }
  }
};
</script>
