<template>
  <div>
    <div
      v-if="paginationShow == true"
      class="dataTables_length"
      id="DataTables_Table_0_length"
    >
      <label>
        {{ $t("show") }}
        <select
          name="DataTables_Table_0_length"
          aria-controls="DataTables_Table_0"
          class="form-control form-control-sm"
          @input="$emit('entries_change', $event.target.value)"
        >
          <option :value="10" :selected="entries == 10">10</option>
          <option :value="25" :selected="entries == 25">25</option>
          <option :value="50" :selected="entries == 50">50</option>
          <option :value="100" :selected="entries == 100">100</option>
          <option :value="200" :selected="entries == 200">200</option>
          <option :value="500" :selected="entries == 500">500</option>
        </select>
        {{ $t("rows") }}
      </label>
    </div>
    <div id="DataTables_Table_0_filter" class="dataTables_filter">
      <div
        v-if="excel"
        class="btn btn-xs btn-primary my-1 mr-1"
        @click="downloadExcel"
      >
        <i class="fa fa-ferfesh">{{ $t("download_excel") }}</i>
      </div>
      <div
        class="btn btn-xs btn-primary my-1"
        @click="$emit('getData', 1, true)"
      >
        <i class="fa fa-ferfesh">{{ $t("refresh_table") }}</i>
      </div>
    </div>
    <div
      v-if="paginationShow == true"
      class="dataTables_info"
      id="DataTables_Table_0_info"
      role="status"
      aria-live="polite"
    >
      Showing {{ entries * (current_page - 1) + 1 }} to {{ to }} of
      {{ total }} entries
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseComponent",
  props: {
    entries: {
      type: Number,
      required: true
    },
    current_page: {
      type: Number,
      required: true
    },
    last_page: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    to: {
      type: Number,
      required: true
    },
    excel: {
      type: Boolean,
      default: false
    },
    paginationShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    downloadExcel() {
      console.log("hello");
      this.$emit("downloadExcel");
    }
  }
};
</script>
